import '../../scss/facade.scss';
import getAudioResourceFromUrl from '../service/url-to-audio';
import { createMediaElement, getResourceIdFromResourceUrl } from '../utils/utils';
import { UrlToAudioRes } from '../../types';
import sendTrackingEvent from '../service/tracking';
import trackingStore from '../store/tracking-store';

// eslint-disable-next-line
export let htmlMediaElement;
let audioRes: UrlToAudioRes | void;

waitForElm('.etx-player').then(async (etxPlayer: HTMLDivElement) => {
  // === START JS SCRIPT ===
  let version = '2.3.1';
  etxPlayer.dataset.v = version;
  const etxPlayerParent = etxPlayer.parentElement;
  const positions = ['relative', 'absolute'];
  if (etxPlayerParent && !positions.includes(etxPlayerParent.style.position)) {
    etxPlayerParent.classList.add('etx-relative');
  }
  const etxPlayerDataset = etxPlayer.dataset;
  const { listenArticleText } = etxPlayerDataset;
  const duration = getDurationForClient(Number(etxPlayerDataset.duration));
  const { disabled, hideCredits, showdisabled, linkDisabled } = etxPlayerDataset;
  const poweredByLink =
    linkDisabled === 'true'
      ? `<strong style="display: inline-block;
  font-size: 10px;
  color: var(--creditTextColor, #828282);
  font-weight: 700;">ETX Studio</strong>`
      : "<a href='https://hubs.ly/Q019HQ2Y0' target='_blank'>ETX Studio</a>";

  const poweredBy =
    hideCredits === 'true' || window.location.hostname === 'madame.lefigaro.fr'
      ? ''
      : `
            <div class='facade-brand'>
                <span class='facade-brand-powered'>Powered</span>
                <span class='facade-brand-powered-by'>by</span>
                <span class='facade-brand-powered-by-trademark'>
                    ${poweredByLink}
                </span>
            </div>`;
  const playBtnAlign = etxPlayerDataset.playBtnAlign ? Number(etxPlayerDataset.playBtnAlign.replace('px', '')) : '-1';
  let bgPlayer = window.getComputedStyle(document.querySelector('.etx-player')).getPropertyValue('background-color');
  if (bgPlayer === 'rgba(0, 0, 0, 0)') {
    bgPlayer = 'rgb(255, 255, 255)';
  }
  const buttonPlay = `
                <button class='facade-button-play'>
                    <span class='facade-icon-play'></span>
                </button>`;
  const durationHTML = `<span class='facade-duration'>00:00/${duration}</span>`;
  const progress = `
            <div class='facade-progress'>
                <input ${disabled === 'true' ? 'disabled' : ''} type='range' min='0' max='0' value='0' style=''>
                ${duration ? durationHTML : ''}
            </div>`;
  const label = `
                <div class='facade-container-label'>
                    <span class='facade-label'>${listenArticleText || 'Écouter cet article'}</span>
                    ${poweredBy}
                </div>`;
  const facadeContainer = `
                <div class='facade-container ${disabled === 'true' ? 'disabled' : ''}' style='${
  etxPlayerDataset.mainColor ? '--mainColor:' + etxPlayerDataset.mainColor + ';' : ''
}${etxPlayerDataset.playBtnRadius ? '--playBtnRadius:' + etxPlayerDataset.playBtnRadius + ';' : ''}${
  etxPlayerDataset.playBtnColor ? '--playBtnColor:' + etxPlayerDataset.playBtnColor + ';' : ''
}${etxPlayerDataset.listenTextColor ? '--listenTextColor:' + etxPlayerDataset.listenTextColor + ';' : ''}${
  etxPlayerDataset.creditTextColor ? '--creditTextColor:' + etxPlayerDataset.creditTextColor + ';' : ''
}${etxPlayerDataset.playBtnAlign ? '--playBtnAlign:' + etxPlayerDataset.playBtnAlign + ';' : ''}${
  etxPlayerDataset.timeColor ? '--timeColor:' + etxPlayerDataset.timeColor + ';' : ''
}${etxPlayerDataset.listenTextFont ? '--listenTextFont:' + etxPlayerDataset.listenTextFont + ';' : ''} --bgPlayer: ${bgPlayer};'>
                    <div class='facade-container-button-play'>
                        ${buttonPlay}
                    </div>
                    <div class='facade-container-track'>
                        ${label}
                        ${progress}
                    </div>
                </div>`;
  if (etxPlayer) {
    etxPlayer.insertAdjacentHTML('afterbegin', facadeContainer);
    initTrackingIds();
    const urlParams = new URLSearchParams(window.location.search);
    version = urlParams.get('version') || etxPlayer.getAttribute('data-version') || '';
    const url = urlParams.get('url') || etxPlayer.getAttribute('data-url') || window.location.href;
    addEventListeners(url);
    waitPlayerToBeReady();
    // Helps us determine if client is in scrapping mode or API mode, if the url is defined in the dataset, they are using our API.
    // Therefore, we shouldn't call /url-to-audio endpoint
    if (!('url' in etxPlayerDataset)) {
      const res = await preFetchUrlToAudio({ url, version, apiKey: etxPlayerDataset.apiKey });
      if (res) {
        setDuration(res.duration);
        trackingStore.resourceId = res.id;
        sendTrackingEvent({ eventType: 'display', eventValue: true });
      }
    } else {
      trackingStore.resourceId = etxPlayerDataset.resourceid || getResourceIdFromResourceUrl(etxPlayerDataset.url);
      sendTrackingEvent({ eventType: 'display', eventValue: true });
    }
  }

  async function waitPlayerToBeReady() {
    waitForElm('.etx-player.ep-wrapper.hidden').then(() => {
      etxPlayer.classList.remove('hidden');
      const facadeContainerElem = document.querySelector('.etx-player .facade-container') as HTMLElement;
      facadeContainerElem.remove();
      const lastButton = etxPlayer.querySelector('.op-controls__playpause:last-of-type') as HTMLElement;
      if (lastButton) {
        lastButton.style.opacity = '1';
      }
    });
  }

  function addEventListeners(url: string) {
    const facadeBtnPlay = document.querySelector('.facade-container .facade-button-play') as HTMLElement;
    if (facadeBtnPlay) {
      const facadeIsDisabled = document.querySelector('.etx-player[data-disabled="true"]');
      if (facadeIsDisabled) {
        return;
      }
      const events = ['click', 'touchstart'];
      events.forEach((event) => {
        facadeBtnPlay.addEventListener(event, async () => {
          etxPlayer.dataset.earlyStart = String(true);
          const loaderHTML = '<div class="lds-ring-v1"><div></div><div></div><div></div><div></div></div>';
          const loaderWrapper = document.createElement('div');
          loaderWrapper.innerHTML = loaderHTML;
          setLoadingState({
            playPauseElement: facadeBtnPlay,
            loaderWrapper,
            isLoading: true,
          });

          htmlMediaElement = createMediaElement(url);
          htmlMediaElement.setAttribute('playsinline', '');
          htmlMediaElement.setAttribute('type', 'audio/mpeg');
          // FIXME: class op-player__media isnt added if its here
          // htmlMediaElement.classList.add('op-player__media');
          htmlMediaElement.setAttribute('controls', '');
          htmlMediaElement.setAttribute('src', '');
          htmlMediaElement.setAttribute('class', 'ep-player');
          htmlMediaElement.setAttribute('id', 'ep-player');
          htmlMediaElement.dataset.r = trackingStore.resourceId;
          htmlMediaElement.dataset.s = trackingStore.sessionId;
          import(
            /* webpackChunkName: "mainbaseplayer" */
            /* webpackMode: "lazy" */
            './embed-player'
          );
        });
      });
    }
  }

  /**
   * It adds or removes a loading state to a button
   */
  function setLoadingState({ playPauseElement, isLoading, loaderWrapper }) {
    const element = playPauseElement;
    if (isLoading) {
      element.innerHTML = '';
      element.appendChild(loaderWrapper);
      element.setAttribute('disabled', String(true));
      element.classList.add('etx-no-hover', String(true));
    }

    if (!isLoading) {
      loaderWrapper.remove();
    }
  }

  // === END JS SCRIPT ===
});

function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document && document.querySelector(selector)) {
      resolve(document.querySelector(selector));
      return;
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

function getDurationForClient(durationInSeconds: number): string {
  if (Number.isNaN(durationInSeconds)) return '00:00';
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);
  const seconds = Math.floor(durationInSeconds - hours * 3600 - minutes * 60);

  return hours
    ? addZeroToNumber(hours) + ':' + addZeroToNumber(minutes) + ':' + addZeroToNumber(seconds)
    : addZeroToNumber(minutes) + ':' + addZeroToNumber(seconds);
}

function addZeroToNumber(value: number): string {
  return value < 10 ? '0' + value : String(value);
}

async function preFetchUrlToAudio({ url, version, apiKey }) {
  const payload = {
    url: url,
    version: version,
    apiKey: apiKey,
  };

  audioRes = await getAudioResourceFromUrl(payload);
  return audioRes;
}

function setDuration(duration) {
  const durationElem = document.querySelector('.facade-duration');
  const durationValue = getDurationForClient(duration);
  durationElem.innerHTML = `00:00/${durationValue}`;
}

function initTrackingIds() {
  if (!localStorage) return;
  const etxSettings = JSON.parse(localStorage.getItem('etx-settings')) || {};
  if (!etxSettings.tracking) {
    etxSettings.tracking = {
      longSession: {
        id: crypto.randomUUID(),
        timestamp: new Date(),
      },
      permanentSession: {
        id: crypto.randomUUID(),
        timestamp: new Date(),
      },
    };
    localStorage.setItem('etx-settings', JSON.stringify(etxSettings));
  }
}
