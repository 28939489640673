import fetchUrlToAudioCache from './cache';
import handleCanonicalUrl from '../utils/canonical-url';

// TODO: this is a GET request for /url-to-audio endpoint, its functional but needs a cache feature
// export const getAudioResourceFromUrl = async (payload: { url: string; isCanonical: boolean; version: string; apiKey: string }) => {
//   const params = {
//     headers: new Headers({
//       'content-type': 'application/json',
//       'x-api-key': payload.apiKey,
//     }),
//   };
//   const queryString = Object.entries(payload)
//     .filter(([key, value]) => value !== null && value !== '')
//     .map(([key, value]) => `${key}=${value}`)
//     .join('&');
//
//   // const reqUrl = `${process.env.MEDIA_API_HOST}/url-to-audio?${queryString}`;
//   const reqUrl = `https://dailyup-api-beta.etxstudio.com/url-to-audio?${queryString}`;
//   return await fetchRetry(reqUrl, params);
// };

const getAudioResourceFromUrl = async ({ url, version, apiKey }: { url: string; version: string; apiKey: string }) => {
  const prePayload = handleCanonicalUrl(url);
  const params = {
    body: JSON.stringify({ ...prePayload, version }),
    method: 'post',
    headers: new Headers({
      'content-type': 'application/json',
      'x-api-key': apiKey,
    }),
  };

  const data = await fetchUrlToAudioCache(`${process.env.MEDIA_API_HOST}/url-to-audio`, params).catch((err) => console.error(err));
  return data;
};

export default getAudioResourceFromUrl;
